//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    //这里接受参数
    data: {
      type: Object,
      default: () => {},
    },
    formLink: {
      type: String,
      default: '',
    },
    isModel: {
      type: Boolean,
      default: true,
    },
    pType: {
      type: String,
      default: '',
    },
    pId: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isStatistics: {
      type: [String, Number],
      default: 1,
    },
    myCollect: {
      type: [Function, Object],
      default: null,
    },
  },
  data() {
    //这里存放数据
    return {
      timerId: null,
      wechat: '',
      link: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    $domain() {
      return this.prefixImageUrl(process.env.NODE_ENV)
    },
    ctaStyle() {
      let ctaStyle = ''
      if (this.data.background_img != '') {
        ctaStyle = `backgroundImage:url(${this.$domain}${this.data.background_img});`
      } else {
        ctaStyle = `background:${this.data.background_color};`
      }
      return ctaStyle
    },
    btnStyle() {
      let btnStyle = 'color:#2B3D4F'
      if (this.data.button_style == 2) {
        return (btnStyle = `background:transparent !important;border:1px solid ${
          this.data.button_color || '#FF724F'
        };color:#2B3D4F`)
      }
      if (this.data.button_style == 3) {
        btnStyle = 'borderRadius:20px;color:#2B3D4F;'
        return `background:${this.data.button_color}!important;borderColor:${this.data.button_color};${btnStyle}`
      }
      return `background:${this.data.button_color}!important;borderColor:${this.data.button_color};${btnStyle}`
    },
    aTarget() {
      return this.link ? (this.data.new_window ? '_blank' : '_self') : ''
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.tohref()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.wechat = this.$route.query.wechat ? this.$route.query.wechat : ''
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {},
  //方法集合
  methods: {
    handleCollect() {
      // 点击埋点
      let params = {
        parent_page_id: this.pId,
        page_id: this.data.id, // 必传 页面id
        page_first_title: this.data.cta_name, // 可传 页面一级标题
        source_title: this.pType, // 可传 页面来源
        module_type: 'cta',
        element_name: 'lcta',
        target_type: 'lcta',
        first_floor_type: 'content',
        first_floor_id: this.pId,
        system_type: 'renew',
        is_not_stat: 0,
        wechat_openid:
          localStorage.getItem(this.wechat + 'wechat_openid') || '',
        wechat_appid: localStorage.getItem(this.wechat + 'wechat_appid') || '',
        wechat_fans_info: localStorage.getItem(this.wechat + 'wechat_fans_info')
          ? localStorage.getItem(this.wechat + 'wechat_fans_info')
          : '{}',
      }
      if (this.isStatistics == 1) {
        // console.log('213', params)
        this.myCollect && this.myCollect.$eventClick(params)
      }
      console.log('触发麦点事件 one')
    },
    tohref() {
      let link = ''
      let wechat = this.$route.query.wechat ? this.$route.query.wechat : ''

      let wechatFlag = wechat ? `&wechat=${wechat}` : ''

      if (this.data.link_type == 2) {
        let Linker = this.data.link.indexOf('?') > -1 ? '&' : '?'
        if (this.data.link.includes('https')) {
          link = `${this.data.link}${Linker}module=cms&gType=${this.pType}&gId=${this.pId}&pType=lcta&pId=${this.id}&isStatistics=${this.isStatistics}${wechatFlag}`
        } else {
          link = `https://${this.data.link}${Linker}module=cms&gType=${this.pType}&gId=${this.pId}&pType=lcta&pId=${this.id}&isStatistics=${this.isStatistics}${wechatFlag}`
        }
      } else {
        if (this.data.link.includes('https')) {
          link = this.data.link
        } else {
          link = `${this.data.link}`
        }
      }
      // let originPage = getOriginPage(window.location)
      // if (originPage) {
      //   if (link.includes('?')) {
      //     link += '&originPage=' + encodeURIComponent(originPage)
      //   } else {
      //     link += '?originPage=' + encodeURIComponent(originPage)
      //   }
      // }
      // if (this.data.new_window == 1) {
      //   window.open(link)
      // } else {
      //   window.location.href = link
      // }
      this.link = link
    },
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
