//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import engconfig from '../../envconfig'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    //这里接受参数
    data: {
      type: Object,
      default: () => {},
    },
    formLink: {
      type: String,
      default: '',
    },
    isModel: {
      type: Boolean,
      default: true,
    },
    pType: {
      type: String,
      default: '',
    },
    pId: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isStatistics: {
      type: [String, Number],
      default: 1,
    },
    host: {
      type: String,
      default: '',
    },
    iframeFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    //这里存放数据
    return {
      timerId: null,
      formClick: true,
      link: '',
      cdpParams: {
        form_id: '1005',
        gId: '640',
        gType: 'blog',
      },
    }
  },
  //监听属性 类似于data概念
  computed: {
    ctaStyle() {
      return `background:${this.data.background_color};`
    },
    iframeLink() {
      // alert(this.isStatistics)
      let link = ''
      if (this.isStatistics == 1) {
        if (this.host.includes('https')) {
          link = `${this.data.form_link}&module=cms&gType=${this.pType}&gId=${this.pId}&pType=fcta&pId=${this.id}&isStatistics=${this.isStatistics}&ctaBackground=${this.data.background_color}`
        } else {
          link = `${this.data.form_link}&module=cms&gType=${this.pType}&gId=${this.pId}&pType=fcta&pId=${this.id}&isStatistics=${this.isStatistics}&ctaBackground=${this.data.background_color}`
        }
      } else {
        link = `${this.data.form_link}&module=cms&gType=${this.pType}&gId=${this.pId}&pType=fcta&pId=${this.id}&isStatistics=${this.isStatistics}&ctaBackground=${this.data.background_color}`
      }

      // if (this.contentId) {
      //   link = `${this.data.form_link}&ct=${this.contentType}&cv=${this.contentId}`
      // }
      return link
    },
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.splicingLinkParams()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // alert(this.data.form_link)
    // this.splicingLinkParams()
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {
    document.addEventListener('message', this.openForm)
    if (process.browser) {
      window.addEventListener('message', (e) => {
        if (e.data.type == 'cdp') {
          this.openForm()
        }
      })
    }
  }, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    document.removeEventListener('message', this.openForm)
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // openIfraem() {
    //   alert(123456)
    //   window.parent.postMessage(
    //     {
    //       command: 'open',
    //     },
    //     '*'
    //   )
    // },
    closeIframe(type) {
      if (type == 'shrink') {
        window.parent.postMessage(
          {
            command: 'close',
          },
          '*'
        )
      } else {
        window.parent.postMessage(
          {
            command: 'open',
          },
          '*'
        )
      }
    },
    openForm() {
      this.formClick = false
    },
    closeForm() {
      this.formClick = true
    },
    imageServerDomain() {
      return engconfig[process.env.NODE_ENV].VUE_APP_DOMAIN
    },
    apiBaseUrl() {
      return engconfig[process.env.NODE_ENV].API_BASE_URL
    },
    //拼接连接参数
    splicingLinkParams() {
      let org_id = this.data.org_id
      let form_id = this.data.form_id
      if (this.isStatistics == 1) {
        this.link = `${
          engconfig[process.env.NODE_ENV].API_BASE_URL
        }?org_id=${org_id}&form_id=${form_id}&module=cms&gType=${
          this.pType
        }&gId=${this.pId}&pType=fcta&pId=${this.id}&isStatistics=${
          this.isStatistics
        }&ctaBackground=${this.data.background_color}`
      } else {
        this.link = `${
          engconfig[process.env.NODE_ENV].API_BASE_URL
        }?org_id=${org_id}&form_id=${form_id}&module=cms&gType=${
          this.pType
        }&gId=${this.pId}&pType=fcta&pId=${this.id}&isStatistics=${
          this.isStatistics
        }&ctaBackground=${this.data.background_color}`
      }
      this.handleParams(this.link)
    },
    //截取参数
    handleParams(url) {
      var paramObj = {}
      if (url.indexOf('?') > -1) {
        var _url = url.split('?')[1].split('#')[0]
        var strArr = _url.split('&')
        strArr.forEach((ele) => {
          var key = ele.split('=')[0]
          var val = ele.split('=')[1]
          paramObj[key] = val
        })
      }
      this.cdpParams = paramObj
      this.cdpParams.ctaBackground = this.data.background_color
    },
  },
}
