//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ctaForm from '~/components/cta/cta-form.vue'
import ctaLinkOne from '~/components/cta/cta-link-temp-one.vue'
import ctaLinkTwo from '~/components/cta/cta-link-temp-two.vue'
import ctaLinkThe from '~/components/cta/cta-link-temp-the.vue'
export default {
  name: 'CtaComponent',
  components: { ctaForm, ctaLinkOne, ctaLinkTwo, ctaLinkThe },
  props: {
    ctaLink: {
      type: String,
      default: '',
    },
    isM: {
      type: Boolean,
      default: false,
    },
  },
  //import引入的组件需要注入到对象中才能使用 ct, cv
  // async asyncData({ app, query }) {
  //   let asyncData = {}
  //   const { id, release, org_id, pType, pId, isStatistics, isCol } = query
  //   let params = {
  //     id: Number(id),
  //     release: 1,
  //     org_id,
  //   }
  //   await new Promise((resolve) => {
  //     app.$cta.getCtaDetail(params).then(({ code, data, msg }) => {
  //       console.log(data)
  //       if (code == 0) {
  //         asyncData = {
  //           cta_data: data,
  //           id,
  //           release,
  //           org_id,
  //           pType,
  //           pId,
  //           isStatistics,
  //           isCol,
  //         }
  //         resolve()
  //       } else {
  //         console.log('msg', msg, app)
  //       }
  //     })
  //   })

  //   return asyncData
  // },
  data() {
    //这里存放数据
    return {
      ctaModelType: 'ctaForm',
      formLink: '',
      id: null,
      release: null,
      org_id: '',
      pType: '',
      pId: '',
      module: 'cta',
      cta_data: {},
      myCollect: null,
      customer_id: '',
      ctaParams: {},
      wechat: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.wechat = this.$route.query.wechat ? this.$route.query.wechat : ''
    this.handleParams(this.ctaLink)
    if (
      process.browser &&
      this.ctaParams.isStatistics &&
      this.ctaParams.isStatistics == 1
    ) {
      this.customer_id = localStorage.getItem('customer_id')
        ? localStorage.getItem('customer_id')
        : ''
      this.myCollect = new window.Collect({
        org_id: this.ctaParams.org_id,
        customer_id: this.customer_id,
        module_type: 'cta',
        baseUrl: this.$trackUrl,
        target_type: 'cta',
        system_type: 'renew',
        is_not_stat: 1,
        wechat_openid:
          localStorage.getItem(this.wechat + 'wechat_openid') || '',
        wechat_appid: localStorage.getItem(this.wechat + 'wechat_appid') || '',
        wechat_fans_info: localStorage.getItem(this.wechat + 'wechat_fans_info')
          ? localStorage.getItem(this.wechat + 'wechat_fans_info')
          : '{}',
        first_floor_type: 'content',
        first_floor_id: this.ctaParams.pId,
      })
      this.myCollect.init({
        page_view_auto: false,
        element_click_auto: false,
        events: ['BUTTON'],
      })
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // //页面访问买点
    if (
      process.browser &&
      this.ctaParams.isStatistics &&
      this.ctaParams.isStatistics == 1
    ) {
      let params = {
        parent_page_id: this.ctaParams.pId ? this.ctaParams.pId : '',
        page_id: this.ctaParams.id ? this.ctaParams.id : '', // 必传 页面id
        page_first_title: this.cta_data.cta_name, // 可传 页面一级标题
        source_title: this.ctaParams.pType ? this.ctaParams.pType : '', // 可传 页面来源
        module_type: this.module,
        first_floor_type: 'content',
        target_type: 'cta',
        first_floor_id: this.ctaParams.pId,
        system_type: 'renew',
        wechat_openid:
          localStorage.getItem(this.wechat + 'wechat_openid') || '',
        wechat_appid: localStorage.getItem(this.wechat + 'wechat_appid') || '',
        wechat_fans_info: localStorage.getItem(this.wechat + 'wechat_fans_info')
          ? localStorage.getItem(this.wechat + 'wechat_fans_info')
          : '{}',
      }
      this.myCollect.$visit(params)
    }
    // alert(this.ctaLink)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {},
  //方法集合
  methods: {
    //处理链接里的参数
    handleParams(url) {
      // console.log(url, '23456')
      var paramObj = {}
      if (url.indexOf('?') > -1) {
        var _url = url.split('?')[1].split('#')[0]
        var strArr = _url.split('&')
        strArr.forEach((ele) => {
          var key = ele.split('=')[0]
          var val = ele.split('=')[1]
          paramObj[key] = val
        })
      }
      this.ctaParams = paramObj
      // console.log(this.ctaParams, '23456')
      this.getDetail()
    },
    getDetail() {
      let params = {
        id: Number(this.ctaParams.id),
        release: 1,
        org_id: this.ctaParams.org_id,
      }
      this.$cta.getCtaDetail(params).then((res) => {
        if (res.code == 0) {
          this.cta_data = res.data
          this.cta_data.org_id = this.ctaParams.org_id
          if (this.cta_data.type == 2) {
            this.ctaModelType = 'ctaForm'
          } else if (this.cta_data.type == 1) {
            if (this.cta_data.template == 1) {
              this.ctaModelType = 'ctaLinkOne'
            } else if (this.cta_data.template == 2) {
              this.ctaModelType = 'ctaLinkTwo'
            } else if (this.cta_data.template == 3) {
              this.ctaModelType = 'ctaLinkThe'
            }
          }
        }
        // console.log('213', this.cta_data)
      })
    },
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
